$( function() {

  var faqItems = document.querySelectorAll('.p-faq__item');
  faqItems.forEach(function (faqItem) {
    faqItem.addEventListener('click', function () {
      this.classList.toggle('is-active');
    });
  });
  var modalTriger = document.querySelector('.p-guarantee__link');
  modalTriger.addEventListener('click', function () {
    document.querySelector('.c-modal').classList.add('is-active');
  });

  function closeModal(c) {
    document.querySelector(c).addEventListener('click', function () {
      document.querySelector('.c-modal').classList.remove('is-active');
    });
  }


  closeModal('.c-modal__svg');
  closeModal('.c-modal');

  function removeBChild() {
    var bChilds = document.querySelectorAll('.b-child');
    bChilds.forEach(function (bChild) {
      console.log(bChild);
      bChild.classList.remove('is-active');
    });
    var iacs = document.querySelectorAll('.is-active--opacity');
    iacs.forEach(function (iac) {
      console.log(iac);
      iac.classList.remove('is-active--opacity');
    });
  }

  function removeYChild() {
    var yChilds = document.querySelectorAll('.y-child');
    yChilds.forEach(function (yChild) {
      console.log(yChild);
      yChild.classList.remove('is-active');
    });
    var iacs = document.querySelectorAll('.is-active--opacity');
    iacs.forEach(function (iac) {
      console.log(iac);
      iac.classList.remove('is-active--opacity');
    });
  }

  function bc(bClass) {
    document.querySelector(bClass).classList.add('is-active');
  }

  // document.querySelector('.b-2').addEventListener('click', function () {
  //   removeBChild();
  //   bc('.b-2-child'); // setTimeout(function() {
  //   //   document.querySelector('.b-2-child').classList.add('is-active--opacity');
  //   // }, 1500);
  // });
  // document.querySelector('.b-3').addEventListener('click', function () {
  //   removeBChild();
  //   bc('.b-3-child'); // setTimeout(function() {
  //   //   document.querySelector('.b-3-child').classList.add('is-active--opacity');
  //   // }, 1500);
  // });
  // document.querySelector('.b-4').addEventListener('click', function () {
  //   removeBChild();
  //   bc('.b-4-child'); // setTimeout(function() {
  //   //   document.querySelector('.b-4-child').classList.add('is-active--opacity');
  //   // }, 1500);
  // });

  function yc(yClass) {
    document.querySelector(yClass).classList.add('is-active');
  }

  // document.querySelector('.y-2').addEventListener('click', function () {
  //   removeYChild();
  //   yc('.y-2-child'); // setTimeout(function() {
  //   //   document.querySelector('.y-2-child').classList.add('is-active--opacity');
  //   // }, 1500);
  // });
  // document.querySelector('.y-3').addEventListener('click', function () {
  //   removeYChild();
  //   yc('.y-3-child'); // setTimeout(function() {
  //   //   document.querySelector('.y-3-child').classList.add('is-active--opacity');
  //   // }, 1500);
  // });
  // document.querySelector('.y-4').addEventListener('click', function () {
  //   removeYChild();
  //   yc('.y-4-child'); // setTimeout(function() {
  //   //   document.querySelector('.y-4-child').classList.add('is-active--opacity');
  //   // }, 1500);
  // });

  $( '.p-course__tabs__item--b' ).on( 'click', function() {
    $('.p-course__tabs__item--y').removeClass('active');
    $(this).addClass('active');
    $('.p-course__tabs__y').removeClass('active');
    $('.p-course__tabs__b').addClass('active');
  });

  $( '.p-course__tabs__item--y' ).on( 'click', function() {
    $('.p-course__tabs__item--b').removeClass('active');
    $(this).addClass('active');
    $('.p-course__tabs__b').removeClass('active');
    $('.p-course__tabs__y').addClass('active');

  });


//   $('#schedule1').datepicker({
//     dayNames: ['日','月','火','水','木','金','土'],
//     dateFormat: 'mm/dd(DD)',
//     changeMonth:true,
//     monthNames: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
//     monthNamesShort:  ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
//     showMonthAfterYear: true,

//     beforeShowDay: function(date) {
// 			// 日曜日
// 			if (date.getDay() == 0) {
// 				return [true, 'sunday'];
// 			}
// 			// 土曜日
// 			if (date.getDay() == 6) {
// 				return [true, 'saturday'];
// 			}
// 			// 平日
// 			return [true, 'weekday'];
// 		},

//     onSelect: function(date) {
//       if ( date.match(/日/)) {
//         $('.aaa').addClass('active');
//        }else if ( date.match(/土/)){
//         $('.aaa').removeClass('active');
//        }

//   },

// });

$.get("https://holidays-jp.github.io/api/v1/date.json", function(holidaysData) {
  $("#schedule1").datepicker({
    dayNames: ['日','月','火','水','木','金','土'],
    dateFormat: 'mm/dd(DD)',
    changeMonth:true,
    monthNames: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
    monthNamesShort:  ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
    showMonthAfterYear: true,
    minDate: 0, // 過去の日付は選択不可
    beforeShowDay: function(date) {
      if (date.getDay() == 0) {
        return [true, 'day-sunday', null];
      } else if (date.getDay() == 6) {
        return [true, 'day-saturday', null];
      }else if (date.getDay() == 1) {
        return [false, 'ui-state-disabled'];
      }

      var holidays = Object.keys(holidaysData);
      for (var i = 0; i < holidays.length; i++) {
        var holiday = new Date(Date.parse(holidays[i]));
        if (holiday.getYear() == date.getYear() &&
            holiday.getMonth() == date.getMonth() &&
            holiday.getDate() == date.getDate()) {
            return [true, 'day-holiday', '1'];
        }
      }

      return [true, 'day-weekday', null];
    },


        onSelect: function(dateText, inst) {
          $('#schedule1').removeClass('error');
          $('#schedule1-error').remove();
          if ( dateText.match(/土/)) {
            $(".weekend-true").prop("disabled", false);
            $(".weekend-false").prop("disabled", true);
            $(".weekend-first1").prop("selected", true);
            $(".weekend-last1").prop("selected", true);
            $(".weekday-first1").prop("selected", false);
            $(".weekday-last1").prop("selected", false);
           }else if ( dateText.match(/日/)){
            $(".weekend-true").prop("disabled", false);
            $(".weekend-false").prop("disabled", true);
            $(".weekend-first1").prop("selected", true);
            $(".weekend-last1").prop("selected", true);
            $(".weekday-first1").prop("selected", false);
            $(".weekday-last1").prop("selected", false);
           }else if ( dateText.match(/7\/18/)){
            $(".weekend-true").prop("disabled", false);
            $(".weekend-false").prop("disabled", true);
            $(".weekend-first1").prop("selected", true);
            $(".weekend-last1").prop("selected", true);
            $(".weekday-first1").prop("selected", false);
            $(".weekday-last1").prop("selected", false);
           }else if ( dateText.match(/8\/11/)){
            $(".weekend-true").prop("disabled", false);
            $(".weekend-false").prop("disabled", true);
            $(".weekend-first1").prop("selected", true);
            $(".weekend-last1").prop("selected", true);
            $(".weekday-first1").prop("selected", false);
            $(".weekday-last1").prop("selected", false);
           }else if ( dateText.match(/9\/19/)){
            $(".weekend-true").prop("disabled", false);
            $(".weekend-false").prop("disabled", true);
            $(".weekend-first1").prop("selected", true);
            $(".weekend-last1").prop("selected", true);
            $(".weekday-first1").prop("selected", false);
            $(".weekday-last1").prop("selected", false);
           }else if ( dateText.match(/10\/10/)){
            $(".weekend-true").prop("disabled", false);
            $(".weekend-false").prop("disabled", true);
            $(".weekend-first1").prop("selected", true);
            $(".weekend-last1").prop("selected", true);
            $(".weekday-first1").prop("selected", false);
            $(".weekday-last1").prop("selected", false);
           }else if ( dateText.match(/11\/03/)){
            $(".weekend-true").prop("disabled", false);
            $(".weekend-false").prop("disabled", true);
            $(".weekend-first1").prop("selected", true);
            $(".weekend-last1").prop("selected", true);
            $(".weekday-first1").prop("selected", false);
            $(".weekday-last1").prop("selected", false);
           }else if ( dateText.match(/01\/01/)){
            $(".weekend-true").prop("disabled", false);
            $(".weekend-false").prop("disabled", true);
            $(".weekend-first1").prop("selected", true);
            $(".weekend-last1").prop("selected", true);
            $(".weekday-first1").prop("selected", false);
            $(".weekday-last1").prop("selected", false);
           }else if ( dateText.match(/01\/02/)){
            $(".weekend-true").prop("disabled", false);
            $(".weekend-false").prop("disabled", true);
            $(".weekend-first1").prop("selected", true);
            $(".weekend-last1").prop("selected", true);
            $(".weekday-first1").prop("selected", false);
            $(".weekday-last1").prop("selected", false);
           }else if ( dateText.match(/01\/09/)){
            $(".weekend-true").prop("disabled", false);
            $(".weekend-false").prop("disabled", true);
            $(".weekend-first1").prop("selected", true);
            $(".weekend-last1").prop("selected", true);
            $(".weekday-first1").prop("selected", false);
            $(".weekday-last1").prop("selected", false);
           }else if ( dateText.match(/02\/11/)){
            $(".weekend-true").prop("disabled", false);
            $(".weekend-false").prop("disabled", true);
            $(".weekend-first1").prop("selected", true);
            $(".weekend-last1").prop("selected", true);
            $(".weekday-first1").prop("selected", false);
            $(".weekday-last1").prop("selected", false);
           }else if ( dateText.match(/02\/23/)){
            $(".weekend-true").prop("disabled", false);
            $(".weekend-false").prop("disabled", true);
            $(".weekend-first1").prop("selected", true);
            $(".weekend-last1").prop("selected", true);
            $(".weekday-first1").prop("selected", false);
            $(".weekday-last1").prop("selected", false);
           }else if ( dateText.match(/03\/21/)){
            $(".weekend-true").prop("disabled", false);
            $(".weekend-false").prop("disabled", true);
            $(".weekend-first1").prop("selected", true);
            $(".weekend-last1").prop("selected", true);
            $(".weekday-first1").prop("selected", false);
            $(".weekday-last1").prop("selected", false);
           }else{
            $(".weekend-true").prop("disabled", true);
            $(".weekend-false").prop("disabled", false);
            $(".weekend-first1").prop("selected", false);
            $(".weekend-last1").prop("selected", false);
            $(".weekday-first1").prop("selected", true);
            $(".weekday-last1").prop("selected", true);
           }
      }
  });

  $("#schedule2").datepicker({
    dayNames: ['日','月','火','水','木','金','土'],
    dateFormat: 'mm/dd(DD)',
    changeMonth:true,
    monthNames: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
    monthNamesShort:  ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
    showMonthAfterYear: true,
    minDate: 0, // 過去の日付は選択不可
    beforeShowDay: function(date) {
      if (date.getDay() == 0) {
        return [true, 'day-sunday', null];
      } else if (date.getDay() == 6) {
        return [true, 'day-saturday', null];
      }else if (date.getDay() == 1) {
        return [false, 'ui-state-disabled'];
      }

      var holidays = Object.keys(holidaysData);
      for (var i = 0; i < holidays.length; i++) {
        var holiday = new Date(Date.parse(holidays[i]));
        if (holiday.getYear() == date.getYear() &&
            holiday.getMonth() == date.getMonth() &&
            holiday.getDate() == date.getDate()) {
            return [true, 'day-holiday', '1'];
        }
      }

      return [true, 'day-weekday', null];
    },


        onSelect: function(dateText, inst) {
          $('#schedule2').removeClass('error');
          $('#schedule2-error').remove();
          if ( dateText.match(/土/)) {
            $(".weekend-true2").prop("disabled", false);
            $(".weekend-false2").prop("disabled", true);
            $(".weekend-first2").prop("selected", true);
            $(".weekend-last2").prop("selected", true);
            $(".weekday-first2").prop("selected", false);
            $(".weekday-last2").prop("selected", false);
           }else if ( dateText.match(/日/)){
            $(".weekend-true2").prop("disabled", false);
            $(".weekend-false2").prop("disabled", true);
            $(".weekend-first2").prop("selected", true);
            $(".weekend-last2").prop("selected", true);
            $(".weekday-first2").prop("selected", false);
            $(".weekday-last2").prop("selected", false);
           }else if ( dateText.match(/7\/18/)){
            $(".weekend-true2").prop("disabled", false);
            $(".weekend-false2").prop("disabled", true);
            $(".weekend-first2").prop("selected", true);
            $(".weekend-last2").prop("selected", true);
            $(".weekday-first2").prop("selected", false);
            $(".weekday-last2").prop("selected", false);
           }else if ( dateText.match(/8\/11/)){
            $(".weekend-true2").prop("disabled", false);
            $(".weekend-false2").prop("disabled", true);
            $(".weekend-first2").prop("selected", true);
            $(".weekend-last2").prop("selected", true);
            $(".weekday-first2").prop("selected", false);
            $(".weekday-last2").prop("selected", false);
           }else if ( dateText.match(/9\/19/)){
            $(".weekend-true2").prop("disabled", false);
            $(".weekend-false2").prop("disabled", true);
            $(".weekend-first2").prop("selected", true);
            $(".weekend-last2").prop("selected", true);
            $(".weekday-first2").prop("selected", false);
            $(".weekday-last2").prop("selected", false);
           }else if ( dateText.match(/10\/10/)){
            $(".weekend-true2").prop("disabled", false);
            $(".weekend-false2").prop("disabled", true);
            $(".weekend-first2").prop("selected", true);
            $(".weekend-last2").prop("selected", true);
            $(".weekday-first2").prop("selected", false);
            $(".weekday-last2").prop("selected", false);
           }else if ( dateText.match(/11\/03/)){
            $(".weekend-true2").prop("disabled", false);
            $(".weekend-false2").prop("disabled", true);
            $(".weekend-first2").prop("selected", true);
            $(".weekend-last2").prop("selected", true);
            $(".weekday-first2").prop("selected", false);
            $(".weekday-last2").prop("selected", false);
           }else if ( dateText.match(/01\/01/)){
            $(".weekend-true2").prop("disabled", false);
            $(".weekend-false2").prop("disabled", true);
            $(".weekend-first2").prop("selected", true);
            $(".weekend-last2").prop("selected", true);
            $(".weekday-first2").prop("selected", false);
            $(".weekday-last2").prop("selected", false);
           }else if ( dateText.match(/01\/02/)){
            $(".weekend-true2").prop("disabled", false);
            $(".weekend-false2").prop("disabled", true);
            $(".weekend-first2").prop("selected", true);
            $(".weekend-last2").prop("selected", true);
            $(".weekday-first2").prop("selected", false);
            $(".weekday-last2").prop("selected", false);
           }else if ( dateText.match(/01\/09/)){
            $(".weekend-true2").prop("disabled", false);
            $(".weekend-false2").prop("disabled", true);
            $(".weekend-first2").prop("selected", true);
            $(".weekend-last2").prop("selected", true);
            $(".weekday-first2").prop("selected", false);
            $(".weekday-last2").prop("selected", false);
           }else if ( dateText.match(/02\/11/)){
            $(".weekend-true2").prop("disabled", false);
            $(".weekend-false2").prop("disabled", true);
            $(".weekend-first2").prop("selected", true);
            $(".weekend-last2").prop("selected", true);
            $(".weekday-first2").prop("selected", false);
            $(".weekday-last2").prop("selected", false);
           }else if ( dateText.match(/02\/23/)){
            $(".weekend-true2").prop("disabled", false);
            $(".weekend-false2").prop("disabled", true);
            $(".weekend-first2").prop("selected", true);
            $(".weekend-last2").prop("selected", true);
            $(".weekday-first2").prop("selected", false);
            $(".weekday-last2").prop("selected", false);
           }else if ( dateText.match(/03\/21/)){
            $(".weekend-true2").prop("disabled", false);
            $(".weekend-false2").prop("disabled", true);
            $(".weekend-first2").prop("selected", true);
            $(".weekend-last2").prop("selected", true);
            $(".weekday-first2").prop("selected", false);
            $(".weekday-last2").prop("selected", false);
           }else{
            $(".weekend-true2").prop("disabled", true);
            $(".weekend-false2").prop("disabled", false);
            $(".weekend-first2").prop("selected", false);
            $(".weekend-last2").prop("selected", false);
            $(".weekday-first2").prop("selected", true);
            $(".weekday-last2").prop("selected", true);
           }
      }
  });

  $("#schedule3").datepicker({
    dayNames: ['日','月','火','水','木','金','土'],
    dateFormat: 'mm/dd(DD)',
    changeMonth:true,
    monthNames: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
    monthNamesShort:  ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
    showMonthAfterYear: true,
    minDate: 0, // 過去の日付は選択不可
    beforeShowDay: function(date) {
      if (date.getDay() == 0) {
        return [true, 'day-sunday', null];
      } else if (date.getDay() == 6) {
        return [true, 'day-saturday', null];
      }else if (date.getDay() == 1) {
        return [false, 'ui-state-disabled'];
      }

      var holidays = Object.keys(holidaysData);
      for (var i = 0; i < holidays.length; i++) {
        var holiday = new Date(Date.parse(holidays[i]));
        if (holiday.getYear() == date.getYear() &&
            holiday.getMonth() == date.getMonth() &&
            holiday.getDate() == date.getDate()) {
            return [true, 'day-holiday', '1'];
        }
      }

      return [true, 'day-weekday', null];
    },


        onSelect: function(dateText, inst) {
          if ( dateText.match(/土/)) {
            $(".weekend-true3").prop("disabled", false);
            $(".weekend-false3").prop("disabled", true);
            $(".weekend-first3").prop("selected", true);
            $(".weekend-last3").prop("selected", true);
            $(".weekday-first3").prop("selected", false);
            $(".weekday-last3").prop("selected", false);
           }else if ( dateText.match(/日/)){
            $(".weekend-true3").prop("disabled", false);
            $(".weekend-false3").prop("disabled", true);
            $(".weekend-first3").prop("selected", true);
            $(".weekend-last3").prop("selected", true);
            $(".weekday-first3").prop("selected", false);
            $(".weekday-last3").prop("selected", false);
           }else if ( dateText.match(/7\/18/)){
            $(".weekend-true3").prop("disabled", false);
            $(".weekend-false3").prop("disabled", true);
            $(".weekend-first3").prop("selected", true);
            $(".weekend-last3").prop("selected", true);
            $(".weekday-first3").prop("selected", false);
            $(".weekday-last3").prop("selected", false);
           }else if ( dateText.match(/8\/11/)){
            $(".weekend-true3").prop("disabled", false);
            $(".weekend-false3").prop("disabled", true);
            $(".weekend-first3").prop("selected", true);
            $(".weekend-last3").prop("selected", true);
            $(".weekday-first3").prop("selected", false);
            $(".weekday-last3").prop("selected", false);
           }else if ( dateText.match(/9\/19/)){
            $(".weekend-true3").prop("disabled", false);
            $(".weekend-false3").prop("disabled", true);
            $(".weekend-first3").prop("selected", true);
            $(".weekend-last3").prop("selected", true);
            $(".weekday-first3").prop("selected", false);
            $(".weekday-last3").prop("selected", false);
           }else if ( dateText.match(/10\/10/)){
            $(".weekend-true3").prop("disabled", false);
            $(".weekend-false3").prop("disabled", true);
            $(".weekend-first3").prop("selected", true);
            $(".weekend-last3").prop("selected", true);
            $(".weekday-first3").prop("selected", false);
            $(".weekday-last3").prop("selected", false);
           }else if ( dateText.match(/11\/03/)){
            $(".weekend-true3").prop("disabled", false);
            $(".weekend-false3").prop("disabled", true);
            $(".weekend-first3").prop("selected", true);
            $(".weekend-last3").prop("selected", true);
            $(".weekday-first3").prop("selected", false);
            $(".weekday-last3").prop("selected", false);
           }else if ( dateText.match(/01\/01/)){
            $(".weekend-true3").prop("disabled", false);
            $(".weekend-false3").prop("disabled", true);
            $(".weekend-first3").prop("selected", true);
            $(".weekend-last3").prop("selected", true);
            $(".weekday-first3").prop("selected", false);
            $(".weekday-last3").prop("selected", false);
           }else if ( dateText.match(/01\/02/)){
            $(".weekend-true3").prop("disabled", false);
            $(".weekend-false3").prop("disabled", true);
            $(".weekend-first3").prop("selected", true);
            $(".weekend-last3").prop("selected", true);
            $(".weekday-first3").prop("selected", false);
            $(".weekday-last3").prop("selected", false);
           }else if ( dateText.match(/01\/09/)){
            $(".weekend-true3").prop("disabled", false);
            $(".weekend-false3").prop("disabled", true);
            $(".weekend-first3").prop("selected", true);
            $(".weekend-last3").prop("selected", true);
            $(".weekday-first3").prop("selected", false);
            $(".weekday-last3").prop("selected", false);
           }else if ( dateText.match(/02\/11/)){
            $(".weekend-true3").prop("disabled", false);
            $(".weekend-false3").prop("disabled", true);
            $(".weekend-first3").prop("selected", true);
            $(".weekend-last3").prop("selected", true);
            $(".weekday-first3").prop("selected", false);
            $(".weekday-last3").prop("selected", false);
           }else if ( dateText.match(/02\/23/)){
            $(".weekend-true3").prop("disabled", false);
            $(".weekend-false3").prop("disabled", true);
            $(".weekend-first3").prop("selected", true);
            $(".weekend-last3").prop("selected", true);
            $(".weekday-first3").prop("selected", false);
            $(".weekday-last3").prop("selected", false);
           }else if ( dateText.match(/03\/21/)){
            $(".weekend-true3").prop("disabled", false);
            $(".weekend-false3").prop("disabled", true);
            $(".weekend-first3").prop("selected", true);
            $(".weekend-last3").prop("selected", true);
            $(".weekday-first3").prop("selected", false);
            $(".weekday-last3").prop("selected", false);
           }else{
            $(".weekend-true3").prop("disabled", true);
            $(".weekend-false3").prop("disabled", false);
            $(".weekend-first3").prop("selected", false);
            $(".weekend-last3").prop("selected", false);
            $(".weekday-first3").prop("selected", true);
            $(".weekday-last3").prop("selected", true);
           }
      }
  });
});














  var mediaSwiper = new Swiper(".mediaSwiper", {
    pagination: {
      el: ".mediaSwiper-pagination",
      clickable: true
    },

    autoplay: {
      delay: 3000,
    },
    speed: 1000,
    loopedSlides: 5,
    freeMode: false,
    loop: true,
    breakpoints: {
      769: {
        speed:1000,
      }
    }
  });
  var storySwiper = new Swiper(".storySwiper", {
    pagination: {
      el: ".storySwiper-pagination",
      clickable: true
    },
    navigation: {
      nextEl: ".storySwiper-next",
      prevEl: ".storySwiper-prev"
    },
    loop: true,
    speed: 1000,
    slidesPerView: 1,
    spaceBetween: 0,
    centeredSlides: true,
    breakpoints: {
      481: {
        slidesPerView: 1.5,
        spaceBetween: 60
      },
      769: {
        slidesPerView: 3,
        spaceBetween: 60
      }
    }
  });


  var reasonSwiper = new Swiper(".reasonSwiper", {
    pagination: {
      el: ".reasonSwiper-pagination",
      clickable: true
    },
    navigation: {
      nextEl: ".reasonSwiper-next",
      prevEl: ".reasonSwiper-prev"
    },

    loop: true,
    speed: 1000,
    slidesPerView: 1,
    spaceBetween: 0,
    centeredSlides: true,

    breakpoints: {
      481: {
        slidesPerView: 1.5,
        spaceBetween: 60
      },
      769: {
        slidesPerView: 3,
        spaceBetween: 60
      }
    }
  });

});



$(function(){
	if (window.matchMedia('(max-width: 1100px)').matches) {
		$(function(){
      // #で始まるa要素をクリックした場合に処理（"#"←ダブルクォーテンションで囲むのを忘れずに。忘れるとjQueryのバージョンによっては動かない。。）
      $('a[href^="#"]').click(function(){
        // 移動先を0px調整する。0を30にすると30px下にずらすことができる。
        var adjust = -70;
        // スクロールの速度（ミリ秒）
        var speed = 400;
        // アンカーの値取得 リンク先（href）を取得して、hrefという変数に代入
        var href= $(this).attr("href");
        // 移動先を取得 リンク先(href）のidがある要素を探して、targetに代入
        var target = $(href == "#" || href == "" ? 'html' : href);
        // 移動先を調整 idの要素の位置をoffset()で取得して、positionに代入
        var position = target.offset().top + adjust;
        // スムーススクロール linear（等速） or swing（変速）
        $('body,html').animate({scrollTop:position}, speed, 'swing');
        return false;
      });
    });
	} else  {
		$(function(){
      // #で始まるa要素をクリックした場合に処理（"#"←ダブルクォーテンションで囲むのを忘れずに。忘れるとjQueryのバージョンによっては動かない。。）
      $('a[href^="#"]').click(function(){
        // 移動先を0px調整する。0を30にすると30px下にずらすことができる。
        var adjust = -80;
        // スクロールの速度（ミリ秒）
        var speed = 400;
        // アンカーの値取得 リンク先（href）を取得して、hrefという変数に代入
        var href= $(this).attr("href");
        // 移動先を取得 リンク先(href）のidがある要素を探して、targetに代入
        var target = $(href == "#" || href == "" ? 'html' : href);
        // 移動先を調整 idの要素の位置をoffset()で取得して、positionに代入
        var position = target.offset().top + adjust;
        // スムーススクロール linear（等速） or swing（変速）
        $('body,html').animate({scrollTop:position}, speed, 'swing');
        return false;
      });
    });
}});

